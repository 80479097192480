<template>
  <div>
    <div class="navBox">
      <a-button @click="addSubject" type="primary"> <span style="margin-right: 3px;">+</span>承运主体</a-button>
    </div>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" rowKey="id" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #status="{ record }">
          <a-popconfirm :title="tipsTitle" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" @cancel="cancel">
            <a-switch size="small" :checked="record.status" @change="swChange($event,record)" :checkedValue="1" :unCheckedValue="0" />
          </a-popconfirm>
        </template>
        <template #add="{ record }">
          <a @click="contractEdit(record)">合同配置</a>
          <a class="m-l3" @click="edit(record)">编辑</a>
        </template>
      </ly-table>
    </div>
    <a-modal v-model:visible="visible" :title="title" :footer="false" destroyOnClose>
      <ly-formList :fromList="fromList" ref="fromListRef" @cancel="visible = false" @getVal="getVal"></ly-formList>
    </a-modal>
    <a-modal title="合同模板" v-model:visible="textVisible" width="40%" destroyOnClose centered :bodyStyle="{padding:'10px 20px'}" :confirmLoading="btnLoading" @ok="richHandleOk">
      <div class="fz-14 m-b1"><span style="color:red">*</span>合同文案<span class="fz-12" style="color:#998">（请做好断句以便展示）</span></div>
      <ly-richText ref="richTextRef" />
      <div class="m-t1 fz-14 m-b1"><span style="color:red">*</span>合同章<span class="fz-12" style="color:#998">（请上传png格式）</span></div>
      <ly-upload ref="uploadRef" @fileStatus="fileStatus" :maxMun="1"></ly-upload>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { columns, fromList } from './carrierSubject'
import { bodyAdd, page, isEnable, bodyDetail, bodyNameEdit, bodyEdit } from '@/api/system/carrierSubject'
import { message } from 'ant-design-vue';

const title = ref('添加主体')
const data = ref([])
const textVisible = ref(false)
const visible = ref(false)
const tipsTitle = ref('')
const editID = ref('')
const btnLoading = ref(false)
const uploadRef = ref(null)
const richTextRef = ref(null)
const loading = ref(false)
const fromListRef = ref(null)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})

const pageChange = (e) => {
  pagination.value = e
  reqPage()
}
const fileStatus = (e) => {
  btnLoading.value = e
}
const richHandleOk = () => {
  var value = richTextRef.value.editorRef.getText().trim().length
  if(!value){
    message.error('请输入合同文案')
    return
  }
  if(uploadRef.value.state.fileIds.length === 0){
    message.error('请上传合同章')
    return
  }
  btnLoading.value = true
  bodyEdit({
    id:editID.value,
    contractContent:richTextRef.value.valueHtml,
    contractSealId:uploadRef.value.state.fileIds[0].id
  }).then(res => {
    if(res.code !== 10000) return
     message.success('编辑成功')
     textVisible.value = false
     reqPage()
  }).finally(() => {
    btnLoading.value = false
  })

}
const getVal = (e) => {
  fromListRef.value.fileLoading = true
  if (editID.value) {
    let msg = {
      id:editID.value
    }
    Object.assign(msg,e)
    bodyNameEdit(msg).then(res => {
      if (res.code !== 10000) return
      message.success('编辑成功')
      reqPage()
      visible.value = false
    }).finally(() => {
      if(fromListRef.value) {
        fromListRef.value.fileLoading = false
      }
    })
  } else {
    bodyAdd(e).then(res => {
      if (res.code !== 10000) return
      message.success('添加成功')
      reqPage()
      visible.value = false
    }).finally(() => {
      if(fromListRef.value) {
        fromListRef.value.fileLoading = false
      }
    })
  }

}
const swChange = (e, v) => {
  if (v.status === 0) {
    tipsTitle.value = '确定要打开吗？'
  } else {
    tipsTitle.value = '确定要关闭吗？'
  }
}
const confirm = (record) => {
  isEnable(record.id).then(res => {
    if (res.code !== 10000) return
    reqPage()
    message.success('操作成功')
  })
}
const addSubject = () => {
  editID.value = ''
  fromList.value[0].value = ''
  title.value = '添加主体'
  visible.value = true
}
const edit = (item) => {
  bodyDetail(item.id).then(res => {
    if (res.code !== 10000) return
    editID.value = item.id
    fromList.value[0].value = res.data.name
    title.value = '编辑'
    visible.value = true
  })

}
const contractEdit = (item) => {
  bodyDetail(item.id).then(res => {
    if (res.code !== 10000) return 
    textVisible.value = true
    editID.value = item.id
    setTimeout(() => {
      if(res.data.contractContent){
        richTextRef.value.valueHtml = res.data.contractContent
      }
      if( res.data.contractSeal){
        uploadRef.value.state.fileIds =[ res.data.contractSeal]
      }
    }, 50);

  })
  
}

const reqPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  page(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    loading.value = false
    pagination.value.total = res.data.total
  })
}
onMounted(() => {
  reqPage()
})
</script>

<style lang="less" scoped>
.navBox {
  padding: 20px 10px;
  background: #fff;
}
.tableBox {
  padding: 15px;
  background: #ffffff;
  height: calc(100vh - 230px);
}
</style>