<template>
  <div>
    <div style="height:10px"></div>
    <div class="tableBox ">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="收款账户配置" force-render>
            <ReceivingAccount></ReceivingAccount>
        </a-tab-pane>
        <a-tab-pane key="2" tab="PC端logo自定义">
          <div class="m-l2">
            <div class="title"></div>
            <div class="flex">
              <div style="width:40%">
                <span>展开横版LOGO</span>
                <div class="tips m-b2">建议上传分辨率为180*40或360*80像素的png格式图片</div>
                <Upload :type="1"></Upload>
              </div>
              <div>
                <span>收缩竖版LOGO</span>
                <div class="tips m-b2">建议上传分辨率为60*60或160*160像素的png格式图片</div>
                <Upload :type="2"></Upload>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="承运主体配置">
         <CarrierSubject></CarrierSubject>
         </a-tab-pane>
      </a-tabs>

    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import Upload from './upload.vue';
import ReceivingAccount from './components/receivingAccount' 
import CarrierSubject from '@/views/components/carrierSubject'
const activeKey = ref('1')






</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px;
  background: #ffffff;
  height: calc(100vh - 150px);
}

.title {
  font-size: 16px;
  color: #191919;
  margin: 10px 0 20px 0;
}

.tips {
  color: #dc363e;
}
</style>